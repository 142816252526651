<template>
  <div class="waiver-content-index">
    <div class="header-bar">
      <router-link
        :to="{ name: 'waiver-content-show', params: { waiverContentId: 'new' } }"
        tag="button"
      >{{ $t('New waiver version') }}</router-link>
      <!-- <router-link
        :to="{ name: 'waivers', params: { locationId: locationId } }"
        tag="button" class="ghost"
      >{{ $t('Signed waivers') }}</router-link> -->
    </div>

    <div class="empty f-body tc w-60 center fill-geyser fadeIn animated" v-if="noWaiverContents">
      <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
      <i18n tag="p" path="You don't have waivers yet. Click on {newWaiverContentLink} to create one.">
        <template #newWaiverContentLink>
          <router-link
            class="link-style"
            :to="{ name: 'waiver-content-show', params: { waiverContentId: 'new' } }"
          >{{ $t('New waiver version') }}</router-link>
        </template>
      </i18n>
    </div>

    <UIRowIndexLoader v-if="isLoading" />

    <div v-if="!isLoading" class="waiver-content-grid animated fadeIn">
      <div class="waiver-content-card" v-for="waiverContent in waiverContents" :key="waiverContent.id">
        <div class="title f4 nowrap dib truncate slate w">{{ !waiverContent.language ? 'All languages' : waiverContent.language }}</div>
        <UIButton
          tiny
          icon="edit"
          :to="{ name: 'waiver-content-show', params: { waiverContentId: waiverContent.id } }"
        >{{ $t("edit") }}</UIButton>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIRowIndexLoader from '@/core/shared/components/ui/UIRowIndexLoader';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    UIButton,
    UIInput,
    UIIcon,
    UIRowIndexLoader,
  },
})
export default class WaiverContentIndex extends Vue {
  enableWaivers = false;

  get isLoading () {
    return this.$store.state.waiverContents.isFetchingWaiverContents && this.waiverContents.length === 0;
  }

  get noWaiverContents () {
    return !this.$store.state.waiverContents.isFetchingWaiverContents && this.waiverContents.length === 0;
  }

  get waiverContents () {
    return this.$store.getters['entities/WaiverContent'](({ location }) => location.id === this.locationId);
  }

  get location () {
    return this.$store.getters['entities/Location'](this.locationId);
  }

  formatExpiresAt (date) {
    return moment(date).format('LL');
  }

  created () {
    this.$store.commit('setTitle', this.$t('Waiver'));
  }

  mounted () {
    this.$store.dispatch('getLocationWaiverContents', this.locationId);
  }

  beforeDestroy () {
    if (this.clipboard) this.clipboard.destroy();
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.waiver-content-index {
  @apply --container;

  & .header-bar {
    margin: var(--spacingMd) 0 var(--spacingSm) 0;
    text-align: right;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & button {
      @apply --buttonPrimary;

      display: inline-block;
      width: auto;
    }

    & .ghost {
      @apply --buttonGhost;
    }
  }

  & h2 {
    @apply --f3;
  }

  & .waiver-content-grid {
    display: grid;
    grid-gap: var(--spacingSm);

    & .waiver-content-card {
      background-color: var(--colorWhite);
      box-shadow: var(--shadowSm);
      border-radius: var(--radius);

      display: grid;
      grid-template-columns: auto 5.5rem 5.5rem;
      align-items: center;
      grid-gap: var(--spacingSm);
      padding: var(--spacingSm);

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        padding: var(--spacingAltSm) var(--spacingAltMd);
      }
    }
  }
}
</style>
