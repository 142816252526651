<template>
  <div class="waiver-content-show">
    <div class="main-content">
      <div class="top-actions">
        <BreadcrumbNavConnected class="breadcrumb"/>
        <button v-if="canDeleteWaiverContent" @click="deleteWaiverContent" class="delete-waiver-content">
          <svg>
            <use xlink:href="#icon-trash"></use>
          </svg>
          <span>{{ $t("Delete Waiver Version") }}</span>
        </button>
      </div>

      <content-block :required="true">
        <div slot="instructions">
          <h5>{{ $t('Waiver Version Details') }}</h5>
          <p>{{ $t('Write a waiver version for a language. If the language is not set, this content is the fallback content. It will be used when no specific waiver version for your customer\'s browser language is defined.') }}</p>
        </div>
        <div class="details">
          <div class="grid-one">
            <template v-if="isNew">
              <UISelect
                :title="$t('Language')"
                :instructions="$t('The language of this waiver version')"
                v-model="waiverContent.language"
              >
                <option
                  v-for="language in languages"
                  :value="language.key"
                  :key="language.key"
                >{{ language.title }}</option>
              </UISelect>
            </template>
            <template v-else-if="!waiverContent.language">
              <UIInput
                :title="$t('Language')"
                :instructions="$t('This waiver version is shown when there is no specific content for the customer\'s language')"
                :value="$t('All languages')"
                readonly
              />
            </template>
            <template v-else>
              <UIInput
                :title="$t('Language')"
                :value="languageName"
                readonly
              />
            </template>
            <UIInput
              :title="$t('Content')"
              :instructions="$t('The content for the waiver')"
              v-model="waiverContent.content"
              textarea
              height="40vh"
              required
            />
            <UISwitch
              :title="$t('Ask for marketing consent')"
              :value="waiverContent.askForMarketingConsent"
              :instructions="$t('The customer will be asked to consent to receive marketing emails, but is not required.')"
              @change="toggleMarketingConsent($event)"
            />
          </div>
        </div>
      </content-block>
    </div>
    <bottom-bar
      @save="storeWaiverContent"
      :saving="saving"
      :valid="valid"
      :backRouterLink="{ name: 'waiver-content-index' }"
    />
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import BottomBar from '@/core/shared/components/BottomBar';
import { isEqual } from 'lodash-es';
import { Chrome } from 'vue-color';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import UIButton from '@/core/shared/components/ui/UIButton';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';

@Component({
  props: {
    waiverContentId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    ContentBlock,
    BottomBar,
    Chrome,
    UIButton,
    UISwitch,
    UIInput,
    UISelect,
    UIFormItem,
    BreadcrumbNavConnected,
  },
})
export default class WaiverContentShow extends mixins(FocusModeMixin) {
  defaultWaiverContent = {
    startsAt: '',
    language: null,
    askForMarketingConsent: false,
    content: '',
    location: {
      id: this.locationId,
    },
  };

  waiverContentChanges = {};
  saving = false;

  get waiverContent () {
    return new Proxy({ ...this.waiverContentInStore, ...this.waiverContentChanges }, {
      set: (object, property, value) => {
        if (isEqual(this.waiverContentInStore[property], value)) {
          this.$delete(this.waiverContentChanges, property, value);
        } else {
          this.$set(this.waiverContentChanges, property, value);
        }
        return true;
      },
    });
  }

  get isNew () {
    return this.waiverContentId === 'new';
  }

  get canDeleteWaiverContent () {
    return !this.isNew && this.waiverContent.language;
  }

  get waiverContentInStore () {
    const inStore = this.$store.getters['entities/WaiverContent'](this.waiverContentId);
    if (this.isNew) {
      return this.defaultWaiverContent;
    }
    return inStore ?? this.defaultWaiverContent;
  }

  get valid () {
    const changed = this.waiverContentChanges.content !== undefined || this.waiverContentChanges.askForMarketingConsent !== undefined;
    const hasContent = !!this.waiverContent.content && this.waiverContent.content.length > 0;
    return changed && hasContent && (!this.isNew || !!this.waiverContent.language);
  }

  get languageName () {
    return this.languages.filter(l => { return l.key === this.waiverContent.language; })[0]?.title;
  }

  get languages () {
    return this.$store.getters['entities/Language/all']();
  }

  async created () {
    if (this.languages.length === 0) this.$store.dispatch('getLanguages');

    if (!this.isNew && !this.$store.getters['entities/WaiverContent'](this.waiverContentId)) {
      await this.$store.dispatch('getLocationWaiverContents', this.locationId);
    }
    this.show = true;

    let title;
    if (this.isNew) {
      this.waiverContentChanges = this.defaultWaiverContent;
      title = this.$t('New Waiver Version');
    } else {
      this.waiverContentChanges.id = this.waiverContent.id;
      title = this.$t('Edit Waiver Version');
    }

    this.$store.commit('setTitle', title);

    this.setBreadcrumb({
      indexTitle: this.$tc('Waiver', 2),
      editTitle: title,
      indexRouteName: 'waiver-content-index',
    });
  }

  async storeWaiverContent () {
    try {
      await this.$store.dispatch('storeWaiverContent', this.waiverContent);
      this.saving = false;
      this.$store.commit('setFlash', {
        message: `${this.$t('Waiver version saved')}`,
        type: 'success',
      });
      this.$router.push({ name: 'waiver-content-index' });
    } finally {
      this.saving = false;
    }
  }

  toggleMarketingConsent (event) {
    this.waiverContent.askForMarketingConsent = event.target.checked;
  }

  deleteWaiverContent () {
    this.$store.commit('setConfirm', {
      show: true,
      message: this.$t('Are you sure you would like to delete this waiver version?'),
      icon: 'icon-trash',
      buttons: [
        {
          name: this.$t('No'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes'),
          action: async () => {
            await this.$store.dispatch('deleteWaiverContent', this.waiverContent);
            this.$router.push({ name: 'waiver-content-index' });
          },
        },
      ],
    });
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.waiver-content-show {
  & >>> .instructions {
    @media (--desktop) {
      flex: 0 0 20rem;
      margin-right: var(--spacingSm);
    }
  }
  & .helper {
    display: block;
    text-transform: none;
  }
  & .checkbox-label {
    align-items: start;
  }
  & .main-content {
    @apply --container;
  }
  & .breadcrumb {
    margin-right: auto;
  }
  & .top-actions {
    display: flex;
    margin: var(--spacingMd) 0 var(--spacingSm) 0;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & .delete-waiver-content {
      @apply --buttonTinyPrimary;

      margin: 0;
      margin-left: auto;
      width: auto;
    }
  }

  & .grid-one {
    display: grid;
    margin-bottom: var(--spacingMd);
    grid-gap: var(--spacingSm);
    align-items: top;

    & .checkbox {
      position: relative;
      top: 3rem;
    }

    & .example-button {
      @apply --buttonPrimary;
      display: block;
      width: 100%;
    }

    @media (--tablet) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (--desktop) {
      grid-gap: var(--spacingMd);
    }
  }
}
</style>
